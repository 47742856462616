import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import axiosBarwisInstance from '../libs/axios/axios.lib';
import apolloClient from '../libs/apollo/apollo.lib';
import { gql } from '@apollo/client';

class UserStore {
  isAuthenticated = false;
  isAuthenticating = false;
  user = null;
  redirectToLogin = false;
  constructor() {
    makeAutoObservable(this);
  }

  setIsAuthenticated(isAuthenticated) {
    this.isAuthenticated = isAuthenticated;
    return this.isAuthenticated;
  }

  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  setIsAuthenticating(isAuthenticating) {
    this.isAuthenticating = isAuthenticating;
    return this.isAuthenticating;
  }

  getIsAuthenticating() {
    return this.isAuthenticating;
  }

  setRedirectToLogin(redirectToLogin) {
    this.redirectToLogin = redirectToLogin;
    return this.redirectToLogin;
  }

  getRedirectToLogin() {
    return this.redirectToLogin;
  }

  async setCsrfCookie() {
    try {
      await axiosBarwisInstance.get('/sanctum/csrf-cookie');
    } catch (error) {
      throw error;
    }
  }

  async checkAuth() {
    try {
      const query = gql`
        query {
          me {
            id
            first_name
            middle_name
            last_name
            username
            email
            phone
            avatar
            gender
            role {
              id
              name
            }
            barwisAdmin {
              id
            }
            barwisCoach {
              id
            }
            externalCoach {
              id
            }
            externalAdmin {
              id
            }
            athlete {
              id
            }
            client {
              id
            }
          }
        }
      `;
      const response = await apolloClient.query({
        query: query
      });
      if (response.data.me) {
        this.setUser(response.data.me);
        this.setIsAuthenticated(true);
      }
    } catch (error) {
      this.setIsAuthenticated(false);
      throw error;
    }
  }

  async authenticate(email, password) {
    try {
      await this.setCsrfCookie();
      await axiosBarwisInstance.post('/login', {
        email: email,
        password: password
      });
      await this.checkAuth();
    } catch (error) {
      let message;
      if (error.response) {
        message = error.response.data.message;
      }
      if (error.networkError) {
        message = error.networkError.result.message;
      }
      toast.error(message ? message : 'Login failed!');
      throw error;
    }
  }

  async logout() {
    try {
      await axiosBarwisInstance.post('/logout');
      await apolloClient.resetStore();
      await this.checkAuth();
    } catch (error) {
      console.log(error);
    }
  }

  setUser(user) {
    this.user = user;
    return this.user;
  }

  getUser() {
    return this.user;
  }
}

export default new UserStore();
