import { Box, CircularProgress } from '@material-ui/core';

const FallbackLoader = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    style={{ width: '100%', height: '100%' }}
  >
    <CircularProgress />
  </Box>
);

export default FallbackLoader;
