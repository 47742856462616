import React from 'react';
import { Container, Link, useMediaQuery, Grid } from '@material-ui/core';
import useStyles from './style';

const Footer = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <Container maxWidth={false} className={classes.footerContainer}>
      <Grid container spacing={2}>
        <Grid
          item
          sm={12}
          md={3}
          style={{
            textAlign: isDesktop ? 'left' : 'center'
          }}
        >
          Copyright &copy; 2021 Barwis, All Right Reserved.
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          style={{
            textAlign: 'center'
          }}
        >
          <Link className={classes.footerLinks} href='#'>
            Support
          </Link>
          <Link className={classes.footerLinks} href='#'>
            Help Center
          </Link>
          <Link className={classes.footerLinks} href='#'>
            Privacy
          </Link>
          <Link className={classes.footerLinks} href='#'>
            Terms of use
          </Link>
        </Grid>
        <Grid
          item
          sm={12}
          md={3}
          style={{
            textAlign: isDesktop ? 'right' : 'center'
          }}
        >
          Powered By: Zlines Solutions
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
