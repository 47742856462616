import { useEffect, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { CssBaseline, Container, Box } from '@material-ui/core';
import ReactGA from 'react-ga4';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory, useLocation } from 'react-router-dom';
import AppBarTop from './components/AppBarTop';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import LoadingWrapper from 'components/LoadingWrapper';
import { noAuthPaths } from 'config.json';
import userStore from 'stores/user.store';
import useStyles from './style';

const Main = ({ children }) => {
  const classes = useStyles();
  const isAuthenticated = userStore.getIsAuthenticated();
  const isAuthenticating = userStore.getIsAuthenticating();
  const redirectToLogin = userStore.getRedirectToLogin();
  const user = userStore.getUser();
  const { pathname } = useLocation();
  const { push, listen } = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname, title: pathname });
  }, [pathname]);

  useEffect(() => {
    if (redirectToLogin) {
      push('/login');
    }
  }, [redirectToLogin, push]);

  useEffect(() => {
    const unlisten = listen(() => {
      document.querySelector('.mainContainer>div')?.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, [listen]);

  return (
    <LoadingWrapper isLoading={isAuthenticating && user}>
      <Box className={classes.root}>
        <CssBaseline />
        {isAuthenticated && user ? (
          <Fragment>
            <AppBarTop />
            <Sidebar />
            <Box component='main' className={classes.content}>
              <Scrollbars className='mainContainer'>
                <Box className={classes.appBarSpacer} />
                <Container maxWidth={false} className={classes.container}>
                  {children}
                </Container>
                <Footer />
              </Scrollbars>
            </Box>
          </Fragment>
        ) : (
          ''
        )}
        {noAuthPaths.includes(pathname) ? children : ''}
      </Box>
    </LoadingWrapper>
  );
};

export default observer(Main);
