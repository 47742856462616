import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  from
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import userStore from 'stores/user.store';
import { noAuthPaths } from 'config.json';

const baseGraphqlUrl = `${process.env.REACT_APP_API_URL}/graphql`;

const authMiddleWare = new ApolloLink((operation, forward) => {
  const token = Cookies.get('XSRF-TOKEN');
  operation.setContext({
    headers: {
      'Access-Control-Allow-Credentials': true,
      'X-XSRF-TOKEN': token
    }
  });
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: baseGraphqlUrl,
  credentials: 'include'
});

const uploadLink = createUploadLink({
  uri: baseGraphqlUrl,
  credentials: 'include'
});

const logoutLink = onError(({ networkError }) => {
  if (networkError && [401, 419].includes(networkError.statusCode)) {
    if (!noAuthPaths.includes(window.location.pathname)) {
      userStore.setRedirectToLogin(true);
      userStore.setIsAuthenticated(false);
    }
    apolloClient.resetStore();
  }
});

const apolloClient = new ApolloClient({
  link: from([authMiddleWare, logoutLink, uploadLink, httpLink]),
  cache: new InMemoryCache(),
  name: 'barwis-web-app',
  queryDeduplication: false,
  defaultOptions: {
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  connectToDevTools: true
});

export default apolloClient;
