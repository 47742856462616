import axios from 'axios';
import userStore from 'stores/user.store';

const axiosBarwisInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

// Add a request interceptor
axiosBarwisInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosBarwisInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      userStore.setIsAuthenticated(false);
    }
    return Promise.reject(error);
  }
);

export default axiosBarwisInstance;
