import _ from 'lodash';
import theme from 'libs/theme/theme.lib';

export const getUserRoleDisplayName = (role) => {
  let displayName = '';
  switch (role) {
    case 'barwis_admin':
      displayName = 'Admin';
      break;
    case 'BarwisAdmin':
      displayName = 'Admin';
      break;
    case 1:
      displayName = 'Admin';
      break;
    case 'barwis_coach':
      displayName = 'Barwis Coach';
      break;
    case 'BarwisCoach':
      displayName = 'Barwis Coach';
      break;
    case 2:
      displayName = 'Barwis Coach';
      break;
    case 'external_admin':
      displayName = 'External Admin';
      break;
    case 'ExternalAdmin':
      displayName = 'External Admin';
      break;
    case 3:
      displayName = 'External Admin';
      break;
    case 'external_coach':
      displayName = 'External Coach';
      break;
    case 'ExternalCoach':
      displayName = 'External Coach';
      break;
    case 4:
      displayName = 'External Coach';
      break;
    case 'athlete':
      displayName = 'Athlete';
      break;
    case 'Athlete':
      displayName = 'Athlete';
      break;
    case 5:
      displayName = 'Athlete';
      break;
    case 'client':
      displayName = 'Client';
      break;
    case 'Client':
      displayName = 'Client';
      break;
    case 6:
      displayName = 'Client';
      break;
    default:
      displayName = role;
  }

  return displayName;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isObject = (value) => {
  return !!value && value.constructor === Object;
};

export const isValidUrl = (url) => {
  let valid;
  try {
    valid = new URL(url);
  } catch (error) {
    valid = false;
  }
  return !!valid;
};

export const getUrlFromPath = (path, isPrivate = false) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const publicStorageUrl = process.env.REACT_APP_PUBLIC_STORAGE_URL;
  let finalUrl = '';
  if (isValidUrl(path)) {
    finalUrl = path;
  } else if (isPrivate) {
    finalUrl = `${baseUrl}/file/${path}`;
  } else {
    finalUrl = `${publicStorageUrl}/${path}`;
  }
  return finalUrl;
};

export const calcMean = (arr) => {
  const sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length;
};

export const calcMedian = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const calcQuartile = (arr) => {
  const sorted = arr.slice().sort((a, b) => a - b);
  const lowerHalf = sorted.slice(0, Math.floor(sorted.length / 2));
  const upperHalf = sorted.slice(Math.ceil(sorted.length / 2));
  const q1 = calcMedian(lowerHalf);
  const q3 = calcMedian(upperHalf);
  return { q1, q3 };
};

export const calcOutlinerRange = (arr) => {
  const { q1, q3 } = calcQuartile(arr);
  const iqr = q3 - q1;
  let maxValue = q3 + iqr * 1.5;
  let minValue = q1 - iqr * 1.5;

  if (minValue < 0) {
    minValue = 0;
  }

  return { maxValue, minValue };
};

export const addAnnotationToChartOptions = (chartOptions, normative) => {

  if (!normative) {
    return chartOptions;
  }

  const new_chart_options = _.cloneDeep(chartOptions);

  const mean = normative.mean ? normative.mean : 0;
  const median = normative.median ? normative.median : 0;

  let quaMin = 0;
  let quaMax = 0;
  let outlierMin = 0;
  let outlierMax = 0;

  if (normative.quartile) {
    [quaMin, quaMax] = normative.quartile;

    quaMin = quaMin ? quaMin : 0;
    quaMax = quaMax ? quaMax : 0;
  }

  if (normative.outlier) {
    [outlierMin, outlierMax] = normative.outlier;

    outlierMin = outlierMin ? outlierMin : 0;
    outlierMax = outlierMax ? outlierMax : 0;
  }

  let chartAnnotations = {};

  const chartAnnotationCommonOpts = {
    type: 'line',
    borderWidth: 2
  };

  const chartAnnotationCommonMethods = {
    enter: ({ element }, event) => {
      element.label.options.display = true;
      return true;
    },
    leave: ({ element }, event) => {
      element.label.options.display = false;
      return true;
    }
  };

  const chartAnnotationCommonLabelOpts = {
    display: false,
    borderRadius: 10,
    borderWidth: 2,
    color: '#000000',
    rotation: 'auto',
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    font: {
      size: 12,
      weight: 'regular',
      family: theme.typography.fontFamily
    }
  };

  chartAnnotations = {
    mean: {
      ...chartAnnotationCommonOpts,
      borderColor: '#FFFFFF',
      xMin: mean ? mean : 0,
      xMax: mean ? mean : 0,
      label: {
        ...chartAnnotationCommonLabelOpts,
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
        content: `Mean: ${mean.toFixed(2)}`
      },
      ...chartAnnotationCommonMethods
    },
    median: {
      ...chartAnnotationCommonOpts,
      borderColor: '#F5F033',
      xMin: median ? median : 0,
      xMax: median ? median : 0,
      label: {
        ...chartAnnotationCommonLabelOpts,
        backgroundColor: '#F5F033',
        borderColor: '#F5F033',
        content: `Median: ${median.toFixed(2)}`
      },
      ...chartAnnotationCommonMethods
    },
    quartilesMin: {
      ...chartAnnotationCommonOpts,
      borderColor: '#2BAAE6',
      xMin: quaMin ? quaMin : 0,
      xMax: quaMin ? quaMin : 0,
      label: {
        ...chartAnnotationCommonLabelOpts,
        backgroundColor: '#2BAAE6',
        borderColor: '#2BAAE6',
        content: `Quartiles: ${quaMin.toFixed(2)}`
      },
      ...chartAnnotationCommonMethods
    },
    quartilesMax: {
      ...chartAnnotationCommonOpts,
      borderColor: '#2BAAE6',
      xMin: quaMax ? quaMax : 0,
      xMax: quaMax ? quaMax : 0,
      label: {
        ...chartAnnotationCommonLabelOpts,
        backgroundColor: '#2BAAE6',
        borderColor: '#2BAAE6',
        content: `Quartiles: ${quaMax.toFixed(2)}`
      },
      ...chartAnnotationCommonMethods
    },
    outlierMin: {
      ...chartAnnotationCommonOpts,
      borderColor: '#EB212B',
      xMin: outlierMin ? outlierMin : 0,
      xMax: outlierMin ? outlierMin : 0,
      label: {
        ...chartAnnotationCommonLabelOpts,
        backgroundColor: '#EB212B',
        borderColor: '#EB212B',
        content: `Outlier: ${outlierMin.toFixed(2)}`
      },
      ...chartAnnotationCommonMethods
    },
    outlierMax: {
      ...chartAnnotationCommonOpts,
      borderColor: '#EB212B',
      xMin: outlierMax ? outlierMax : 0,
      xMax: outlierMax ? outlierMax : 0,
      label: {
        ...chartAnnotationCommonLabelOpts,
        backgroundColor: '#EB212B',
        borderColor: '#EB212B',
        content: `Outlier: ${outlierMax.toFixed(2)}`
      },
      ...chartAnnotationCommonMethods
    }
  };

  new_chart_options.plugins.annotation.annotations = chartAnnotations;
  new_chart_options.elements.bar.backgroundColor = (value) => {
    /**
     * 
     * value >= Mean & < High Outlier = Green
        value  < Mean & >= Low Outlier = Yellow
        value < Low Outlier = Red
        value >= High Outlier = Blue
     */
    value = value.dataset.data[value.dataIndex];
    let backgroundColor = '#FFFFFF';
    if (value >= mean && value < outlierMax) {
      backgroundColor = 'rgb(0, 175, 63)';
    } else if (value < mean && value >= outlierMin) {
      backgroundColor = 'rgb(253, 200, 47)';
    } else if (value < outlierMin) {
      backgroundColor = 'rgb(255, 0, 0)';
    } else if (value >= outlierMax) {
      backgroundColor = 'rgb(0, 101, 189)';
    }

    return backgroundColor;
  };

  return new_chart_options;
};

export const getUniqeRandomColor = (existingColors) => {
  const randomColor = 'hsl(' + Math.random() * 360 + ', 100%, 75%)';

  if (existingColors.includes(randomColor)) {
    return getUniqeRandomColor(existingColors);
  } else {
    return randomColor;
  }
};
