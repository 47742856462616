import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    letterSpacing: '0.01rem'
  },
  palette: {
    type: 'dark',
    common: {
      black: 'rgba(0, 0, 0, 1)',
      white: 'rgba(255, 255, 255, 1)',
      green: '#4E923A',
      yellow: '#E7BE57',
      red: '#FF0000'
    },
    background: { paper: 'rgba(18, 18, 18, 1)', default: 'rgba(0, 0, 0, 1)' },
    primary: {
      light: 'rgba(250, 250, 250, 0.6)',
      main: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(245, 245, 245, 1)',
      contrastText: 'rgba(0, 0, 0, 1)'
    },
    secondary: {
      light: 'rgba(251, 63, 67, 1)',
      main: 'rgba(255, 0, 0, 1)',
      dark: 'rgba(218, 44, 59, 1)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(255, 255, 255, 1)',
      secondary: 'rgba(255, 0, 0, 1)',
      disabled: 'rgba(229, 115, 115, 1)',
      hint: 'rgba(211, 47, 47, 1)'
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 1)'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'rgba(0, 0, 0, 1)',
        color: 'rgba(255, 255, 255, 1)'
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        borderRadius: 0
      }
    },
    MuiTableHead: {
      root: {
        background: 'rgba(0,0,0,1)',
        padding: '1rem'
      }
    },
    MuiTableBody: {
      root: {
        marginTop: '1rem'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      }
    }
  }
});

export default responsiveFontSizes(theme);
