import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 265;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0
  },
  nested: {
    paddingLeft: theme.spacing(2)
  },
  logo: {
    maxWidth: '80%',
    width: 150,
    fill: '#ffffff',
    cursor: 'pointer'
  },
  mainSidebar: {
    maxHeight: `calc(100% - 220px)`,
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100% - 300px)`
    },
    '&>div': {
      overflowX: 'hidden !important'
    }
  },
  bottomSidebar: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
}));

export default useStyles;
