import { Fragment } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './style';

const CustomButtonPrimary = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      {props.icon ? (
        <IconButton
          size='small'
          className={classes.iconButton}
          onClick={props.onClick}
          title={props?.title}
        >
          {props.icon}
        </IconButton>
      ) : (
        <Button
          startIcon={props.startIcon}
          endIcon={props.endIcon}
          className={classes.button}
          size='small'
          variant='text'
          onClick={props.onClick}
          title={props?.title}
        >
          {props.children}
        </Button>
      )}
    </Fragment>
  );
};

CustomButtonPrimary.propTypes = {
  icon: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.string
};
export default CustomButtonPrimary;
