import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: `${theme.spacing(1)}px`,
    width: 38,
    height: 38,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    '& .MuiBadge-badge': {
      maxWidth: 20,
      height: 20,
      transform: 'scale(0.9) translate(70%, -70%)',
      padding: 5
    },
    '& svg': {
      maxWidth: 20,
      height: 20
    }
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    '& svg': {
      maxWidth: 20,
      height: 20
    }
  }
}));

export default useStyles;
