import { useState, Fragment } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  useMediaQuery,
  Paper,
  Badge,
  ButtonBase,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as AddUserIcon } from './assets/add-user.svg';
import { ReactComponent as GroupChatIcon } from './assets/group-chat.svg';
import { ReactComponent as NotificationIcon } from './assets/notification.svg';
import CustomButtonPrimary from 'components/CustomButtonPrimary';
import { getUserRoleDisplayName } from 'libs/helpers/helper.lib';

import { getUrlFromPath } from 'libs/helpers/helper.lib';
import layoutStore from 'stores/layout.store';
import userStore from 'stores/user.store';
import useStyles from './style';

const AppBarTop = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isAuthenticated = layoutStore.getIsDrawerOpen();
  const isDrawerOpen = layoutStore.getIsDrawerOpen();
  const [profileMenu, setProfileMenu] = useState(null);
  const user = userStore.getUser();
  const { push } = useHistory();
  const today = moment().format('dddd, DD MMM YYYY');
  const userRoleDisplayName = getUserRoleDisplayName(user.role.name);
  const userRoleId = user.role.id;
  const isAllowedToAddMember = [1, 2, 3].includes(+userRoleId);
  return (
    <AppBar
      position='absolute'
      className={clsx(classes.appBar, isAuthenticated && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        {!isDrawerOpen && (
          <IconButton onClick={() => layoutStore.setIsDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}

        <Box className={classes.title}>
          {isDesktop ? (
            <Fragment>
              <Typography
                variant='h5'
                color='inherit'
                noWrap
                className={classes.titleWelecome}
              >
                Hi, {user.first_name}
              </Typography>
              <Typography variant='body1' color='inherit' noWrap>
                {today}
              </Typography>
            </Fragment>
          ) : (
            ''
          )}
        </Box>
        <Box className={classes.topActionButtonContainer}>
          {isAllowedToAddMember ? (
            <Box className={classes.menuButton}>
              <CustomButtonPrimary
                icon={<AddUserIcon />}
                onClick={() => push('/user-management/create-member')}
                className={classes.topActionButtons}
              />
            </Box>
          ) : (
            ''
          )}
          {/* <Box className={classes.menuButton}>
            <CustomButtonPrimary
              icon={<GroupChatIcon />}
              onClick={() => push('/chart')}
              className={classes.topActionButtons}
            />
          </Box> */}
          {/* <Box className={classes.menuButton}>
            <CustomButtonPrimary
              icon={
                <Badge badgeContent={100} color='secondary' max={9}>
                  <NotificationIcon />
                </Badge>
              }
              className={classes.topActionButtons}
              onClick={() => push('/user-management/add-member')}
            />
          </Box> */}
        </Box>
        <ButtonBase
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <Box textAlign='center' mr={2} ml={4}>
            <Typography variant='body1' color='secondary'>
              {user.first_name}
            </Typography>
            <Typography variant='body1'>{userRoleDisplayName}</Typography>
          </Box>
          <Paper>
            {user && user.avatar ? (
              <img
                src={getUrlFromPath(user.avatar)}
                alt='avatar'
                className={classes.avatarImage}
              />
            ) : (
              <Box className={classes.avatarChar}>
                {user && user.email[0].toUpperCase()}
              </Box>
            )}
          </Paper>
        </ButtonBase>
        <Menu
          id='profile-menu'
          anchorEl={profileMenu}
          open={Boolean(profileMenu)}
          onClose={() => setProfileMenu(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: -42,
            horizontal: 'center'
          }}
          classes={{
            paper: classes.profileMenu
          }}
        >
          <MenuItem
            onClick={() => {
              setProfileMenu(null);
              push('/profile');
            }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              setProfileMenu(null);
              userStore.logout();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default observer(AppBarTop);
