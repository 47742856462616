import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useStyles from './style';

const LoadingWrapper = ({ children, isLoading, style }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} style={{ ...style }}>
      {children}
      {isLoading ? (
        <Box className={classes.overlayLoader}>
          <CircularProgress />
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default LoadingWrapper;
