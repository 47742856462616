import { useEffect, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import lazy from 'react-lazy-with-preload';
import ReactGA from 'react-ga4';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'libs/apollo/apollo.lib';
import { ThemeProvider } from '@material-ui/core/styles';
import Layout from './layout';
import FallbackLoader from 'components/FallbackLoader';
import theme from 'libs/theme/theme.lib';
import userStore from 'stores/user.store';
import 'react-toastify/dist/ReactToastify.css';

import comingSoon from 'assets/coming-soon.png';

const Login = lazy(() => import('pages/auth/login'));
const ResetPassword = lazy(() => import('pages/auth/reset-password'));
const Dashboard = lazy(() => import('pages/dashboard'));
const UserManagement = lazy(() => import('pages/user-management'));
const UserProfile = lazy(() => import('pages/user-management/profile'));
const SportManagement = lazy(() => import('pages/sport-management'));
const Forms = lazy(() => import('pages/forms'));
const Reports = lazy(() => import('pages/reports'));
const WorkoutManager = lazy(() => import('pages/workout-manager'));
const FuelingManager = lazy(() => import('pages/fueling-manager'));
const EducationalResourceManager = lazy(() =>
  import('pages/educational-resources')
);
const VideoManager = lazy(() => import('pages/video-manager'));
const FileManager = lazy(() => import('pages/file-manager'));
const FeedbackManager = lazy(() => import('pages/feedback'));
const EventManager = lazy(() => import('pages/event-manager'));
const CMSPages = lazy(() => import('pages/misc-pages'));

toast.configure({
  autoClose: 4000,
  draggable: false,
  position: 'bottom-right'
});

const BarwisApp = () => {
  const isAuthenticated = userStore.getIsAuthenticated();
  const user = userStore.getUser();
  const userRoleId = +user?.role.id;

  const checkAuth = async () => {
    userStore.setIsAuthenticating(true);
    try {
      await userStore.checkAuth();
      userStore.setIsAuthenticating(false);
    } catch (error) {
      userStore.setIsAuthenticating(false);
    }
  };

  const preloadComponents = () => {
    Login.preload();
    ResetPassword.preload();
    Dashboard.preload();
    UserManagement.preload();
    UserProfile.preload();
    SportManagement.preload();
    Forms.preload();
    Reports.preload();
    WorkoutManager.preload();
    FuelingManager.preload();
    EducationalResourceManager.preload();
    VideoManager.preload();
    FileManager.preload();
    FeedbackManager.preload();
    EventManager.preload();
    CMSPages.preload();
  };

  useEffect(() => {
    checkAuth();
    preloadComponents();
  }, []);

  useEffect(() => {
    if (user) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
        gaOptions: {
          userId: `${user.first_name} ${user.last_name}`
        }
      });
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ApolloProvider client={apolloClient}>
          <Layout>
            <Suspense fallback={<FallbackLoader />}>
              <Switch>
                <Route path='/dashboard' component={Dashboard}>
                  {![1, 2, 3, 4].includes(userRoleId) ? (
                    <Box display='flex' justifyContent='center'>
                      <img src={comingSoon} alt='coming soon' width='100%' />
                    </Box>
                  ) : (
                    ''
                  )}
                </Route>
                <Route path='/forms' component={Forms}>
                  {![1, 2, 3, 4].includes(userRoleId) ? (
                    <Redirect to={`/`} />
                  ) : (
                    ''
                  )}
                </Route>
                <Route path='/reports' component={Reports} />
                <Route path='/workout-manager' component={WorkoutManager}>
                  {![1, 2, 3, 4].includes(userRoleId) ? (
                    <Redirect to={`/`} />
                  ) : (
                    ''
                  )}
                </Route>
                <Route path='/fueling-manager' component={FuelingManager} />
                <Route
                  path='/educational-resources'
                  component={EducationalResourceManager}
                >
                  {![1, 2].includes(userRoleId) ? <Redirect to={`/`} /> : ''}
                </Route>
                <Route path='/video-manager' component={VideoManager} />
                <Route path='/event-manager' component={EventManager} />
                <Route path='/file-manager' component={FileManager}>
                  {![1, 2, 3, 4].includes(userRoleId) ? (
                    <Redirect to={`/`} />
                  ) : (
                    ''
                  )}
                </Route>
                <Route path='/sport-management' component={SportManagement} />
                <Route path='/user-management' component={UserManagement} />
                <Route path='/feedbacks' component={FeedbackManager}>
                  {userRoleId !== 1 && <Redirect to='/' />}
                </Route>
                <Route path='/profile' component={UserProfile} />
                <Route path='/cms' component={CMSPages} />
                <Route path='/reset-password' component={ResetPassword}>
                  {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
                </Route>
                <Route path='/login' exact component={Login}>
                  {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
                </Route>
                <Redirect path='/' to='/dashboard' />
              </Switch>
            </Suspense>
          </Layout>
        </ApolloProvider>
      </Router>
    </ThemeProvider>
  );
};

export default observer(BarwisApp);
