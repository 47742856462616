import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: '18px 50px',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paper
  },
  footerLinks: {
    margin: '0 8px'
  }
}));

export default useStyles;
