import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'auto'
  },
  overlayLoader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.background.default,
    opacity: 0.8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99
  }
}));

export default useStyles;
