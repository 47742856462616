import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  useMediaQuery
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ReactComponent as BarwisLogo } from 'assets/logo.svg';
import { ReactComponent as DashboardIcon } from './assets/dashboard.svg';
import { ReactComponent as FormIcon } from './assets/form.svg';
import { ReactComponent as ReportIcon } from './assets/report.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as WhistleIcon } from './assets/whistle.svg';
import { ReactComponent as ProfileIcon } from './assets/profile.svg';
import { ReactComponent as CloseSidebarIcon } from './assets/close-sidebar.svg';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as WorkoutIcon } from './assets/workout.svg';
import { ReactComponent as DietIcon } from './assets/diet.svg';
import { ReactComponent as EducationalResourceIcon } from './assets/educational_resources.svg';
import { ReactComponent as VideoIcon } from './assets/video.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';
import { ReactComponent as FeedbackIcon } from './assets/feedback.svg';
import { Scrollbars } from 'react-custom-scrollbars';

import layoutStore from 'stores/layout.store';
import userStore from 'stores/user.store';
import useStyles from './style';

const Sidebar = () => {
  const [isFormMenuOpen, setIsFormMenuOpen] = useState(false);
  const [isReportMenuOpen, setIsReportMenuOpen] = useState(false);
  const [isWorkoutMenuOpen, setIsWorkoutMenuOpen] = useState(false);
  const classes = useStyles();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const isDrawerOpen = layoutStore.getIsDrawerOpen();
  const isNotDesktop = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const user = userStore.getUser();
  const userRoleId = +user.role.id;

  useEffect(() => {
    if (isNotDesktop) {
      layoutStore.setIsDrawerOpen(false);
    }
  }, [isNotDesktop]);

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !isDrawerOpen && classes.drawerPaperClose
        )
      }}
      open={isDrawerOpen}
    >
      <Box className={classes.toolbarIcon}>
        {isDrawerOpen && (
          <Fragment>
            <BarwisLogo className={classes.logo} onClick={() => push('/')} />
            <IconButton onClick={() => layoutStore.setIsDrawerOpen(false)}>
              <CloseSidebarIcon />
            </IconButton>
          </Fragment>
        )}
      </Box>
      <Scrollbars className={classes.mainSidebar}>
        <List>
          <ListItem
            button
            onClick={() => push('/')}
            selected={pathname === '/dashboard'}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
          <ListItem button onClick={() => setIsFormMenuOpen((value) => !value)}>
            <ListItemIcon>
              <FormIcon />
            </ListItemIcon>
            <ListItemText primary='Forms' />
            {isFormMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isFormMenuOpen} timeout='auto' unmountOnExit>
            <List disablePadding>
              {[1, 2, 3, 4].includes(+userRoleId) ? (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => push('/forms/ars')}
                  selected={pathname === '/forms/ars'}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='ARS' />
                </ListItem>
              ) : (
                ''
              )}
              {userRoleId === 1 ? (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => push('/forms/ars/config')}
                  selected={pathname === '/forms/ars/config'}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='ARS Configuration' />
                </ListItem>
              ) : (
                ''
              )}
              {[1, 2, 3, 4].includes(userRoleId) ? (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => push('/forms/hydration')}
                  selected={pathname === '/forms/hydration'}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='Hydration' />
                </ListItem>
              ) : (
                ''
              )}
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => setIsReportMenuOpen((value) => !value)}
          >
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary='Reports' />
            {isReportMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isReportMenuOpen} timeout='auto' unmountOnExit>
            <List disablePadding>
              {[1, 2, 3, 4].includes(userRoleId) ? (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    push('/reports/readiness-reports/player-readiness-report')
                  }
                  selected={
                    pathname ===
                    '/reports/readiness-reports/player-readiness-report'
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='Player Readiness Report' />
                </ListItem>
              ) : (
                ''
              )}
              {[1, 2, 3, 4].includes(userRoleId) ? (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    push('/reports/readiness-reports/overall-readiness')
                  }
                  selected={
                    pathname === '/reports/readiness-reports/overall-readiness'
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='Overall Readiness' />
                </ListItem>
              ) : (
                ''
              )}
              {[1, 2, 3, 4].includes(+userRoleId) ? (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    push('/reports/readiness-reports/readiness-progress')
                  }
                  selected={
                    pathname === '/reports/readiness-reports/readiness-progress'
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='Readiness Progress' />
                </ListItem>
              ) : (
                ''
              )}
              {[1, 2, 3, 4].includes(+userRoleId) ? (
                <>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() => push('/reports/ars-reports/base-report')}
                    selected={pathname === '/reports/ars-reports/base-report'}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='ARS Reports' />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      push('/reports/ars-reports/player-comparison')
                    }
                    selected={
                      pathname === '/reports/ars-reports/player-comparison'
                    }
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='ARS Player Comparison' />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() => push('/reports/ars-reports/time-series')}
                    selected={pathname === '/reports/ars-reports/time-series'}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='ARS Time Series' />
                  </ListItem>
                </>
              ) : (
                ''
              )}
              {/* <ListItem button className={classes.nested}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary='ARS Base' />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary='Antagonis Agonist' />
              </ListItem> */}
            </List>
          </Collapse>

          {[1, 2, 3, 4].includes(userRoleId) ? (
            <ListItem
              button
              onClick={() => setIsWorkoutMenuOpen((value) => !value)}
            >
              <ListItemIcon>
                <WorkoutIcon />
              </ListItemIcon>
              <ListItemText primary='Workout Manager' />
              {isWorkoutMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          ) : (
            ''
          )}
          <Collapse in={isWorkoutMenuOpen} timeout='auto' unmountOnExit>
            <List disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => push('/workout-manager/exercises')}
                selected={pathname === '/workout-manager/exercises'}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary='Exercises' />
              </ListItem>
            </List>
            <List disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => push('/workout-manager/workouts')}
                selected={pathname === '/workout-manager/workouts'}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary='Workouts' />
              </ListItem>
            </List>
            <List disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => push('/workout-manager/workout-assignments')}
                selected={pathname === '/workout-manager/workout-assignments'}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary='Workout Assignments' />
              </ListItem>
            </List>
          </Collapse>
          {[1, 2].includes(userRoleId) ? (
            <ListItem
              button
              onClick={() => push('/educational-resources')}
              selected={pathname === '/educational-resources'}
            >
              <ListItemIcon>
                <EducationalResourceIcon />
              </ListItemIcon>
              <ListItemText primary='Educational Resources' />
            </ListItem>
          ) : (
            ''
          )}

          {[1, 2, 3, 4].includes(userRoleId) ? (
            <ListItem
              button
              onClick={() => push('/fueling-manager/fueling-plans')}
              selected={pathname === '/fueling-manager/fueling-plans'}
            >
              <ListItemIcon>
                <DietIcon />
              </ListItemIcon>
              <ListItemText primary='Fueling Manager' />
            </ListItem>
          ) : (
            ''
          )}
          {userRoleId === 1 ? (
            <ListItem
              button
              onClick={() => push('/video-manager/video-feeds')}
              selected={pathname === '/video-manager/video-feeds'}
            >
              <ListItemIcon>
                <VideoIcon />
              </ListItemIcon>
              <ListItemText primary='Video Manager' />
            </ListItem>
          ) : (
            ''
          )}
          {[1, 2, 3, 4].includes(+userRoleId) ? (
            <ListItem
              button
              onClick={() => push('/file-manager')}
              selected={pathname === '/file-manager'}
            >
              <ListItemIcon>
                <FileIcon />
              </ListItemIcon>
              <ListItemText primary='File Manager' />
            </ListItem>
          ) : (
            ''
          )}
          {/* <ListItem button onClick={() => push('/')}>
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary='Calendar' />
          </ListItem>
          <ListItem button onClick={() => push('/')}>
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary='Message' />
          </ListItem> */}
          <ListItem
            button
            onClick={() => push('/event-manager')}
            selected={pathname === '/event-manager'}
          >
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary='Events Manager' />
          </ListItem>
          {[1, 2].includes(+userRoleId) ? (
            <ListItem
              button
              onClick={() => push('/sport-management')}
              selected={pathname === '/sport-management'}
            >
              <ListItemIcon>
                <WhistleIcon />
              </ListItemIcon>
              <ListItemText primary='Sports Management' />
            </ListItem>
          ) : (
            ''
          )}
          {[1, 2, 3].includes(userRoleId) ? (
            <ListItem
              button
              onClick={() => push('/user-management')}
              selected={pathname === '/user-management'}
            >
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText primary='User Management' />
            </ListItem>
          ) : (
            ''
          )}
          {/* <ListItem button onClick={() => push('/')}>
            <ListItemIcon>
              <DataManagementIcon />
            </ListItemIcon>
            <ListItemText primary='Data Management' />
          </ListItem> */}
          {userRoleId === 1 ? (
            <ListItem
              button
              onClick={() => push('/feedbacks')}
              selected={pathname === '/feedbacks'}
            >
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary='Feedbacks' />
            </ListItem>
          ) : (
            ''
          )}
        </List>
      </Scrollbars>
      <List className={classes.bottomSidebar}>
        <ListItem button onClick={async () => await userStore.logout()}>
          <ListItemIcon>
            <Box width={30} textAlign='center'>
              <LogoutIcon />
            </Box>
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default observer(Sidebar);
