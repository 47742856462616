import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 265;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed,
    paddingLeft: 12,
    borderBottom: `1px solid ${theme.palette.primary.dark}`
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end'
  },
  titleWelecome: {
    fontWeight: 600,
    marginRight: theme.spacing(4)
  },
  topActionButtonContainer: {
    // marginRight: 20,
    display: 'flex',
    alignItems: 'center'
  },
  topActionButtons: {
    marginRight: 10
  },
  avatarImage: {
    borderRadius: 5,
    width: 38,
    height: 38,
    objectFit: 'cover'
  },
  avatarChar: {
    borderRadius: 5,
    width: 38,
    height: 38,
    fontSize: '1.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  profileMenu: {
    minWidth: 150
  }
}));

export default useStyles;
